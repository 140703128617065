import React from "react";
import ViewRenderer from './base/ViewRenderer';
import {  getBrickCode, getGPCDescriptionWithBrickCheck } from '../../common/helpers/Common.js';
import {palette} from '../../assets/Palette';
import MoreButton from '../../layouts/MoreButton.jsx';
import {getDataSourceLabel} from '../../common/helpers/Common'
import No_image from '../../assets/img/No_Image.png';

export default class AldiViewRenderer extends ViewRenderer {
    defaultLanguage = 'en'
    renderTable(records, listedDict, generateOtherOriginsXMLContent, toggleGPCModalSearch, toggleTradeItemHierarchyModal, onTradeItemHierarchyProduct, completeGPCData, language, downloadInformaticaXML, handleSnackBarOpen, downloadOriginalMessage) {
        let table = []        
        if (records !== null) {
          for (let i = 0; i < records.length; i++) {
            let children = []
            var record = records[i]
            let productName = ''
            let productNameList
            let description = ''
            let descriptionList
            let gpcBrickDescription = ''
            let gpcBrickCode = ''
            let brandName = ''
            let isProductNameLanguageFound = false
            let isDescriptionLanguageFound = false
            let langList = ['de','en','fr','it','sv']
            let sourceLabel = getDataSourceLabel(record["source"])

            if ('tradeItemDescriptionModule' in record.tradeItemInformation.extension && 'tradeItemDescriptionInformation' in record.tradeItemInformation.extension.tradeItemDescriptionModule) {
              if ('functionalName' in record.tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation) {
                productNameList = record.tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.functionalName
               }
              if ('descriptionShort' in record.tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation) {
              descriptionList = record.tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.descriptionShort
              }
              if ('brandNameInformation' in record.tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation) {
                if('brandName' in record.tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.brandNameInformation){
                  brandName = record.tradeItemInformation.extension.tradeItemDescriptionModule.tradeItemDescriptionInformation.brandNameInformation.brandName
                }
              }
            }
            if (productNameList !== null && productNameList !== undefined) {
              for (const langCode of langList){
                if(!isProductNameLanguageFound){
                  for (let i = 0; i < productNameList.length; i++){
                    if (langCode === productNameList[i]['@languageCode']){
                      productName = productNameList[i]['__value__']
                      isProductNameLanguageFound = true
                      break;
                    }
                  }
                }
              }
              if (!isProductNameLanguageFound) {
                productName = productNameList[0]['__value__']
              }
            }
            if (descriptionList !== null && descriptionList !== undefined) {
              for (const langCode of langList){
                if(!isDescriptionLanguageFound){
                  for (let i = 0; i < descriptionList.length; i++){
                    if (langCode === descriptionList[i]['@languageCode']){
                      description = descriptionList[i]['__value__']
                      isDescriptionLanguageFound = true
                      break;
                    }
                  }
                }
              }
              if (!isDescriptionLanguageFound) {
                description = descriptionList[0]['__value__']
              }
            }            
            gpcBrickCode = getBrickCode(record) 
            gpcBrickDescription = getGPCDescriptionWithBrickCheck(gpcBrickCode,completeGPCData,language,this.defaultLanguage)           
                                                        
            if ('thumbnail' in record) {
              let image_tag = <img alt="thumbnail" style={{ maxWidth: '100%', maxHeight: '100%' }} src={No_image} />
              if (record['thumbnail']) {
                image_tag = <img alt="thumbnail" style={{ maxWidth: '100%', maxHeight: '100%' }} src={'data:image/png;base64,' + record['thumbnail']} />
              }
              children.push(<td key={i + ',1'} onClick={() => generateOtherOriginsXMLContent(i)} align='center'>
                <div style={{ margin: 'auto', width: '75px', height: '75px'}}>
                  {image_tag}
                </div>
              </td>)
            } else {
              children.push(<td key={i + ',1'} onClick={() => generateOtherOriginsXMLContent(i)}><div style={{ margin: 'auto', width: '75px', height: '75px' }}><img alt="thumbnail" style={{ maxWidth: '100%', maxHeight: '100%' }} src={No_image} /></div></td>)
            }
            let updatedDate = new Date(record["updatedAt"]).toLocaleString()
            children.push(<td key={i + ',2'} onClick={() => generateOtherOriginsXMLContent(i)}>{record["gtin"]}</td>)
            children.push(<td key={i + ',3'} onClick={() => generateOtherOriginsXMLContent(i)}>{brandName}</td>)
            children.push(<td key={i + ',4'} onClick={() => generateOtherOriginsXMLContent(i)}>{description}</td>)
            children.push(<td key={i + ',5'} onClick={() => generateOtherOriginsXMLContent(i)}>{productName}</td>)
            children.push(<td key={i + ',6'} onClick={() => toggleGPCModalSearch(i)}><div style={{ color: palette.primary.main }}>{gpcBrickCode + ' - ' + gpcBrickDescription}</div></td>)
            if ('manufacturerOfTradeItem' in record && 'partyName' in record['manufacturerOfTradeItem'][0]) {
                children.push(<td key={i + ',7'} onClick={() => generateOtherOriginsXMLContent(i)}>{record["manufacturerOfTradeItem"][0].partyName}</td>)
            } else if ('informationProviderOfTradeItem' in record && 'partyName' in record['informationProviderOfTradeItem']) {
                children.push(<td key={i + ',7'} onClick={() => generateOtherOriginsXMLContent(i)}>{record["informationProviderOfTradeItem"].partyName}</td>)
            } else {
              children.push(<td key={i + ',7'} onClick={() => generateOtherOriginsXMLContent(i)}></td>)
            }
            children.push(<td key={i + ',8'} onClick={() => generateOtherOriginsXMLContent(i)}>{record["tradeItemUnitDescriptorCode"]['__value__'][language]}</td>)
            children.push(<td key={i + ',9'} onClick={() => generateOtherOriginsXMLContent(i)}>{record["countryCodeAlpha2"] + ' (' + record["targetMarket"].targetMarketCountryCode + ')'}</td>)
            children.push(<td key={i + ',10'} onClick={() => generateOtherOriginsXMLContent(i)}>{updatedDate}</td>)
            children.push(<td key={i + ',11'} onClick={() => generateOtherOriginsXMLContent(i)}>{sourceLabel}</td>)
            children.push(<td key={i + ',12'}> 
              <MoreButton 
                product={record}
                recordIndex={i}
                toggleTradeItemHierarchyModal={toggleTradeItemHierarchyModal}
                onTradeItemHierarchyProduct={onTradeItemHierarchyProduct}
                downloadInformaticaXML={downloadInformaticaXML}
                handleSnackBarOpen={handleSnackBarOpen}
                downloadOriginalMessage={downloadOriginalMessage}
              /> </td>)
            table.push(<tr key={i} style={{ cursor: 'pointer' }}>{children}</tr>)
          }
        }
        return table;
      }
    }
import React from "react";
import { withTranslation } from 'react-i18next';
import { CustomerContext } from "../../../common/CustomerContext.js";
import Modal from 'react-modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import TextField from '@mui/material/TextField';
import {palette} from '../../../assets/Palette';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

class PubliclyPublishedProducts extends React.Component {
    static contextType = CustomerContext;

    constructor(props) {
        super(props);
        this.state = {
            publicProducerList: this.props.publicProducerList,
            wineId: '',
            winmakerName: '',
            winmakerAddress: '',
            rowClick:false,
            refreshPublicProducts: this.props.refreshPublicProducts,
            showProductEuvinoSearchModal: false,
            isEuvinoProductSearchModalDisplayed: false,
            currentProducerItem: {},
            searchTerm: '',
            searchResults: [],
            storedPublicProducts: [],
            searchQuery:'',
        };

        this.storePublicProduct = this.storePublicProduct.bind(this);
        this.searchEuvinoProducts = this.searchEuvinoProducts.bind(this);
        this.isStored = this.isStored.bind(this);
        this.fetchWinPublicProductRequests = this.fetchWinPublicProductRequests.bind(this);
    }

    componentDidMount(){
        this.fetchWinPublicProductRequests();
    }
  
    handleSearchChange = (e) => {
        this.setState({ searchQuery: e.target.value });
    };
    
    handleSearch = () => {
        const { publicProducerList, searchQuery } = this.state;
      
        console.log('Handle Search')    
        
        // Create a case-insensitive regular expression from the search query
        const searchRegex = new RegExp(searchQuery, 'i');

        console.log(searchQuery)
      
        // Searching in Data
        const results = publicProducerList.filter(product => {
          // Check if at least one of the keys/properties is defined
          if (
            product &&
            (product.winid !== undefined ||
              product.name !== undefined ||
              product.gtin !== undefined ||
              product.category !== undefined)
          ) {
            const winid = String(product.winid || '');
            const name = String(product.name || '');
            const gtin = String(product.gtin || '');
            const category = String(product.category || '');
      
            // Check if any of the properties(keys) contain the search text using the regular expression
            return (
              searchRegex.test(winid) || searchRegex.test(name) || searchRegex.test(gtin) || searchRegex.test(category)
              
            );
          }
          return false; // If none of the required properties are defined, consider it a non-match
        });

        this.setState({ searchResults: results });

        console.log(results)

      };    

    searchEuvinoProducts(e) {
        const clientId = this.props.clientId
        this.setState(
            { searchTerm: e.target.value }, 
            () => {
                if( this.state.searchTerm.length > 0){
                    this.context.apiService.searchEuvinoProducts(this.props.winId, this.state.searchTerm, clientId)
                    .then(res =>  {
                    if (res.ok) {
                        if(res.status === 204){
                            this.setState({
                                searchResults: [],
                            });
                        } else {
                            res.json().then(res => {
                                this.setState({
                                    searchResults: res,
                            });
                        })
 
                    }
                } else {
                    console.log("searchEuvinoProducts called, Euvino Response");
                    console.log(res.status)
                }
            });   
        } else {
            this.setState({
                searchResults: []
            })
        }
        })
    }

    storePublicProduct(winId){
        console.log("storePublicProduct")
        const clientId = this.props.clientId
        if(!this.state.storedPublicProducts.includes(winId)){
            this.setState(prevState => ({ storedPublicProducts: [...prevState.storedPublicProducts, winId] }));
        }
        this.context.apiService.storePublicProduct(winId, clientId)
    }

    isStored(winId){
        return this.state.storedPublicProducts.includes(winId)

    }
     
    fetchWinPublicProductRequests(){
        console.log("fetchWinPublicProductRequests")
        const clientId = this.props.clientId
        this.context.apiService.fetchWinPublicProductRequests(clientId)
        .then(res => {
            console.log("win_public_product_requests response");
            console.log(res);
            if (res.ok) {

                res.json().then(res => {
                    this.setState({
                        storedPublicProducts: res
                    });
                });
            } else {
                    console.log(res);
                    console.log(res.errors)
            }
        });
    }

    handleChange(event) {
        this.setState({
            searchText: event.target.value
        })
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          this.handleSearch();
        }
      };

    render() {
        const { t } = this.props;
        const { searchQuery } = this.state;
        
        let publicProducts = this.state.searchResults.length > 0 ? this.state.searchResults : this.props.publicProducerList;

        return (
            (<Modal
                    isOpen={this.props.isPubliclyPublishedProductsDisplayed}
                    ariaHideApp={false}
                    >
                <div className='popup'>
                    <div className='popup\_inner'>
                        <div style={{ paddingTop: '4em' }}>
                            <IconButton
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.props.closePubliclyPublishedProducts}
                                style={{ outline: 'none' }}
                                size="large"><CloseRoundedIcon style={{ color: palette.warning.close }} />
                            </IconButton>
                            <h2>{t('euvinoModal.informationAboutProducer')} {this.props.winmakerName}</h2>
                            <br/>

                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Grid container direction="row" justifyContent="flex-start" spacing={1}>
                                    <Grid item>
                                        <TextField
                                            label={t('euvinoModal.searchTerm')}
                                            id="searchText"
                                            value={searchQuery}
                                            variant="outlined" size="small"
                                            onChange={this.handleSearchChange}
                                            onKeyPress={this.handleKeyPress}
                                            style={{ width: '410px' }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button variant="contained"
                                            color="primary"
                                            onClick={this.handleSearch}
                                            style={{ height: '39px' }}
                                        >
                                            {t('euvinoModal.search')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>

                            <br/>

                            <Paper elevation={2}>
                                <div style={{ minHeight: '100px', maxHeight: '741px', overflowY: 'auto', overflowX: 'hidden' }}>    
                                    <Table size="small" aria-label="a   dense table">
                                        <TableHead style={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
                                            <TableRow>
                                            <TableCell align="left"><b>WIN-ID</b></TableCell>
                                                <TableCell align="left"><b>{t('euvinoModal.name')}</b></TableCell>
                                                <TableCell align="center"><b>GTIN</b></TableCell>
                                                <TableCell align="center"><b>{t('euvinoModal.category')}</b></TableCell>
                                                <TableCell align="center"><b>{t('euvinoModal.fillingQuantity')}</b></TableCell>
                                                <TableCell align="center"><b>{t('euvinoModal.year')}</b></TableCell>
                                                <TableCell align="center"><b>{t('euvinoModal.color')}</b></TableCell>
                                                <TableCell align="center"><b>{t('euvinoModal.action')}</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {publicProducts.map(item => (
                                            <TableRow key={item.winid}>
                                                <TableCell align="left">{item.winid}</TableCell>
                                                <TableCell align="left">{item.name}</TableCell>
                                                <TableCell align="center">{item.gtin }</TableCell>
                                                <TableCell align="center">{item.category }</TableCell>
                                                <TableCell align="center">{item.bottle_size }</TableCell>
                                                <TableCell align="center">{item.year }</TableCell>
                                                <TableCell align="center">{item.color }</TableCell>
                                                <TableCell align="center">
                                                    {!this.isStored(item.winid) ? 
                                                        <IconButton
                                                            onClick={this.storePublicProduct.bind(this, item.winid)}
                                                            color="primary"
                                                            size="large">          
                                                            <GetAppRoundedIcon/> 
                                                                </IconButton> : 
                                                                    <IconButton disabled={true} color="primary" size="large">          
                                                            <GetAppRoundedIcon/> 
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </Modal>)
        );
    }
}
export default withTranslation()(PubliclyPublishedProducts);
